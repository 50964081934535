import Model from '../../../Core/data/Model.js';
import ObjectHelper from '../../../Core/helper/ObjectHelper.js';
import StringHelper from '../../../Core/helper/StringHelper.js';
/**
 * @module Scheduler/model/mixin/ProjectModelCommon
 */
/**
 * Mixin that provides helpful methods and properties for a `ProjectModel`. This mixin applies to all Bryntum products.
 * @mixin
 */
export default Target => class ProjectModelCommon extends (Target || Model) {
    static $name = 'ProjectModelCommon';
    static get configurable() {
        return {
            // Documented in Gantt/Scheduler/SchedulerPro version of ./model/ProjectModel since types differ
            assignments  : null,
            dependencies : null,
            resources    : null,
            timeRanges   : null,
            /**
             * Project data as a JSON string, used to populate its stores.
             *
             * ```javascript
             * const project = new ProjectModel({
             *     json : '{"eventsData":[...],"resourcesData":[...],...}'
             * }
             * ```
             *
             * @config {String}
             * @category Inline data
             */
            json : null,
            /**
             * Experimental hook that lets the app determine if a bound dataset needs syncing with the store or not, and
             * if it does - which records that should be processed. Only called for stores that are configured with
             * `syncDataOnLoad: true` (which is the default in the React, Angular and Vue wrappers).
             *
             * {@note}This is an experimental API that might change in any release until it has been finalized.{/@note}
             *
             * The main use case for this is for frameworks that update all state on any state change (like React), to
             * not have to reapply the full bound dataset each time (which becomes costly).
             *
             * If the app has a cheap way of determining (for example using a timestamp) that the dataset has no
             * changes, return `false` from this hook to prevent the store from syncing at all at that time.
             *
             * ```javascript
             * shouldSyncDataOnLoad({ store, data }) {
             *   if (store === myEventStore && timestamp <= lastUpdateTimestamp) {
             *     // Prevent sync when the event store has not changed since last time
             *     // (pseudocode)
             *     return false;
             *   }
             * }
             * ```
             *
             * If the app knows that something has changed, return a `Set` with ids for the records that should be
             * further processed by the sync logic.
             *
             * ```javascript
             * shouldSyncDataOnLoad({ store, data }) {
             *   if (store === myEventStore && timestamp > lastUpdateTimestamp) {
             *     // Sync only these records (pseudocode)
             *     return new Set([6, 110, 586]);
             *   }
             * }
             * ```
             *
             * @prp {Function}
             * @param {Object} options Options passed by the store to this hook
             * @param {Core.data.Store} options.store Store about to be synced
             * @param {Core.data.Model} options.records Records currently in the store
             * @param {Object[]} options.data Incoming data
             * @returns {Set|Boolean} Return `false` to prevent the store from syncing, or a set of
             * record ids that need further processing (for records that has some kind of change, eg. an update, removal
             * or addition)
             * @advanced
             * @category Advanced
             */
            shouldSyncDataOnLoad : null,
            /**
             * Specifies the output format of {@link #function-toJSON}.
             *
             * - `inlineData` - Return all crud store data, plus this project fields (under the `project` key). For example:
             * ```json
             * {
             *     "project" : {
             *         "startDate"    : "2024-03-22",
             *         "endDate"      : "2024-04-22",
             *         "hoursPerDay"  : 8,
             *         "daysPerWeek"  : 5,
             *         "daysPerMonth" : 20
             *     },
             *     "eventsData"             : [...],
             *     "resourcesData"          : [...],
             *     "assignmentsData"        : [...],
             *     "dependenciesData"       : [...],
             *     "resourceTimeRangesData" : [...],
             *     "timeRangesData"         : [...]
             * }
             * ```
             * - `model` - Return only the project fields. For example:
             * ```json
             * {
             *     "startDate"    : "2024-03-22",
             *     "endDate"      : "2024-04-22",
             *     "hoursPerDay"  : 8,
             *     "daysPerWeek"  : 5,
             *     "daysPerMonth" : 20
             * }
             * ```
             *
             * @config {'inlineData'|'model'}
             * @category Inline data
             */
            toJSONResultFormat : 'inlineData'
        };
    }
    // Project is a Model which triggers events, therefore it can define event handlers using `onEvent` syntax. Event
    // handler can be a string (for another instance property), or a function. Therefore, it is impossible to tell them
    // apart and project model can not expose fields.
    // https://github.com/bryntum/support/issues/7457
    static get autoExposeFields() {
        return false;
    }
    //region Inline data
    /**
     * Returns the data from the records of the projects stores, in a format that can be consumed by
     * `loadInlineData()`.
     *
     * Used by JSON.stringify to correctly convert this record to json.
     *
     * The output format is determined by the value of
     * {@link #config-toJSONResultFormat}, as follows:
     *
     * - `inlineData` - Returns all crud store data, plus the project fields (under the `project` key)
     * - `model` - Returns only the the project fields
     *
     * ```javascript
     * const project = new ProjectModel({
     *     // Include both store data and project model fields in toJSON() result
     *     toJSONResultFormat     : 'inlineData',
     *     eventsData             : [...],
     *     resourcesData          : [...],
     *     assignmentsData        : [...],
     *     dependenciesData       : [...],
     *     resourceTimeRangesData : [...],
     *     timeRangesData         : [...]
     * });
     *
     * const json = project.toJSON();
     *
     * // json:
     * {
     *     eventsData             : [...],
     *     resourcesData          : [...],
     *     assignmentsData        : [...],
     *     dependenciesData       : [...],
     *     ...
     *     project                : {
     *         addConstraintOnDateSet : false,
     *         daysPerMonth           : 13
     *         daysPerWeek            : 3
     *         ...
     *     },
     *     ...
     * }
     * ```
     *
     * Output can be consumed by `loadInlineData()`:
     *
     * ```javascript
     * const json = project.toJSON();
     *
     * // Plug it back in later
     * project.loadInlineData(json);
     * ```
     *
     * @returns {Object}
     * @category Inline data
     */
    toJSON() {
        switch (this.toJSONResultFormat) {
            // Project data including its own fields and all related stores data as a
            // combination of crud manager and model APIs
            // { project : { ... }, events : [...], resources : [...], storeId1 : [...] }
            case 'inlineData':
                return this.inlineData;
            // Project fields only
            case 'model':
                return super.toJSON();
        }
    }
    /**
     * Get or set data of project stores. The returned data is identical to what
     * {@link #function-toJSON} returns:
     *
     * ```javascript
     *
     * const data = scheduler.project.inlineData;
     *
     * // data:
     * {
     *     project                : { ... },
     *     eventsData             : [...],
     *     resourcesData          : [...],
     *     dependenciesData       : [...],
     *     assignmentsData        : [...],
     *     resourceTimeRangesData : [...],
     *     timeRangesData         : [...]
     * }
     *
     *
     * // Plug it back in later
     * scheduler.project.inlineData = data;
     * ```
     *
     * @property {Object}
     * @category Inline data
     */
    get inlineData() {
        const me = this;
        let result;
        // If project has crud manager API mixed in - use it
        if (me.isAbstractCrudManagerMixin) {
            result = me.crudStoresJSON;
        }
        else {
            result = {
                eventsData             : me.eventStore.toJSON(),
                resourcesData          : me.resourceStore.toJSON(),
                dependenciesData       : me.dependencyStore.toJSON(),
                timeRangesData         : me.timeRangeStore.toJSON(),
                resourceTimeRangesData : me.resourceTimeRangeStore.toJSON(),
                assignmentsData        : me.assignmentStore.toJSON()
            };
        }
        if (me.eventStore.usesSingleAssignment && result.assignmentsData) {
            delete result.assignmentsData;
        }
        // project fields
        result.project = super.toJSON();
        // project "id" is not needed really
        ObjectHelper.deletePath(result, 'project.id');
        ObjectHelper.deletePath(result, 'project.children');
        return result;
    }
    set inlineData(inlineData) {
        this.loadInlineData(inlineData);
    }
    /**
     * Get or set project data (records from its stores) as a JSON string.
     *
     * Get a JSON string:
     *
     * ```javascript
     * const project = new ProjectModel({
     *     eventsData       : [...],
     *     resourcesData    : [...],
     *     assignmentsData  : [...],
     *     dependenciesData : [...]
     * });
     *
     * const jsonString = project.json;
     *
     * // jsonString:
     * '{"eventsData":[...],"resourcesData":[...],...}'
     * ```
     *
     * Set a JSON string (to populate the project stores):
     *
     * ```javascript
     * project.json = '{"eventsData":[...],"resourcesData":[...],...}'
     * ```
     *
     * @property {String}
     * @category Inline data
     */
    get json() {
        // remember toJSONResultFormat value
        const { toJSONResultFormat } = this;
        // we need toJSON() to use inlineData format
        this.toJSONResultFormat = 'inlineData';
        const result = super.json;
        // restore format
        this.toJSONResultFormat = toJSONResultFormat;
        return result;
    }
    changeJson(json) {
        if (typeof json === 'string') {
            json = StringHelper.safeJsonParse(json);
        }
        return json;
    }
    updateJson(json) {
        json && this.loadInlineData(json);
    }
    get assignments() {
        return this.assignmentStore.allRecords;
    }
    updateAssignments(assignments) {
        this.assignmentStore.data = assignments;
    }
    get dependencies() {
        return this.dependencyStore.allRecords;
    }
    updateDependencies(dependencies) {
        this.dependencyStore.data = dependencies;
    }
    get resources() {
        return this.resourceStore.allRecords;
    }
    updateResources(resources) {
        this.resourceStore.data = resources;
    }
    get timeRanges() {
        return this.timeRangeStore.allRecords;
    }
    getTimeRanges(startDate, endDate) {
        const
            store = this.timeRangeStore,
            ret = [];
        for (const timeSpan of store) {
            // Collect occurrences for the recurring events in the record set
            if (timeSpan.isRecurring) {
                ret.push(...timeSpan.getOccurrencesForDateRange(startDate, endDate));
            }
            else if (timeSpan.startDate < endDate && startDate < timeSpan.endDate) {
                ret.push(timeSpan);
            }
        }
        return ret;
    }
    updateTimeRanges(timeRanges) {
        this.timeRangeStore.data = timeRanges;
    }
    getResourceTimeRanges(startDate, endDate) {
        const
            store = this.resourceTimeRangeStore,
            ret = [];
        for (const timeSpan of store) {
            // Collect occurrences for the recurring events in the record set
            if (timeSpan.isRecurring) {
                ret.push(...timeSpan.getOccurrencesForDateRange(startDate, endDate));
            }
            // A timerange may consist of just a startDate if it is intended to be a line.
            else if (timeSpan.startDate < endDate && (!timeSpan.endDate || startDate < timeSpan.endDate)) {
                ret.push(timeSpan);
            }
        }
        return ret;
    }
    //endregion
};
