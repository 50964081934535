import Column from '../../Grid/column/Column.js';
import ColumnStore from '../../Grid/data/ColumnStore.js';
import VerticalTimeAxis from '../view/VerticalTimeAxis.js';
/**
 * @module Scheduler/column/VerticalTimeAxisColumn
 */
/**
 * A special column containing the time axis labels when the Scheduler is used in vertical mode. You can configure,
 * it using the {@link Scheduler.view.Scheduler#config-verticalTimeAxisColumn} config object.
 *
 * **Note**: this column is sized by flexing to consume full width of its containing {@link Grid.view.SubGrid}. To
 * change width of this column, instead size the subgrid like so:
 *
 * ```javascript
 * const scheduler = new Scheduler({
 *     mode           : 'vertical',
 *     subGridConfigs : {
 *         locked : {
 *             width : 300
 *         }
 *     }
 * });
 * ```
 *
 * @extends Grid/column/Column
 */
export default class VerticalTimeAxisColumn extends Column {
    static $name = 'VerticalTimeAxisColumn';
    static get type() {
        return 'verticalTimeAxis';
    }
    static get defaults() {
        return {
            /**
             * @hideconfigs autoWidth, autoHeight, draggable, groupable, hideable, showColumnPicker, filterable, sortable, searchable, editor, enableCellContextMenu, tooltipRenderer
             */
            draggable             : false,
            groupable             : false,
            hideable              : false,
            showColumnPicker      : false,
            filterable            : false,
            sortable              : false,
            searchable            : false,
            editor                : false,
            enableCellContextMenu : false,
            tooltipRenderer       : false,
            /**
             * Column minimal width. If value is Number then minimal width is in pixels
             * @config {Number|String} minWidth
             * @default 0
             * @category Layout
             */
            minWidth : 0,
            resizable : false,
            cellCls : 'b-verticaltimeaxiscolumn',
            locked  : true,
            flex : 1,
            alwaysClearCell : false
        };
    }
    get isFocusable() {
        return false;
    }
    construct(data) {
        super.construct(...arguments);
        this.view = new VerticalTimeAxis({
            model  : this.grid.timeAxisViewModel,
            client : this.grid
        });
    }
    renderer({ cellElement, size }) {
        this.view.render(cellElement);
        size.height = this.view.height;
    }
    // This function is not meant to be called by any code other than Base#getCurrentConfig().
    // It extracts the current configs (fields) for the column, removing irrelevant ones
    getCurrentConfig(options) {
        const result = super.getCurrentConfig(options);
        // Remove irrelevant configs
        delete result.id;
        delete result.region;
        delete result.type;
        delete result.field;
        delete result.ariaLabel;
        delete result.cellAriaLabel;
        return result;
    }
}
ColumnStore.registerColumnType(VerticalTimeAxisColumn);
VerticalTimeAxisColumn._$name = 'VerticalTimeAxisColumn';